




































































































































































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import CreateObjectiveTrigger from "@/components/objectives/CreateObjectiveTrigger.vue";
import WeeklyEngagement from "@/components/workspace/statistics/WeeklyEngagement.vue";
import ConfettiCanon from "@/components/general/ConfettiCanon.vue";
import {getWeek} from "date-fns";
import VueDraggableResizable from 'vue-draggable-resizable';


@Component({
  components: {ConfettiCanon, WeeklyEngagement, CreateObjectiveTrigger, VueDraggableResizable},
})
export default class DemoList extends Vue {

  completedCount: number = 6;
  triggerConfetti: boolean = false;
  latestCompletedMessage: string | null = "Setup pembio account";
  currentMember: string | null = "You";
  currentWeek: any | null = null;
  content: any = null;
  renderedGoalsList: any = [];

  months: Array<string> = [
    'Aug',
    'Sep',
    'Okt',
    'Nov',
    'Dec',
    'Jan',
    'Feb',
    'Mar',
    'Apr',
  ];

  @Prop({ required: false, default: 2 })
  step!: number;

  @Prop({default: false, required: false})
  inRegister!: boolean;

  @Prop({default: false, required: false})
  runAnimation!: boolean;

  @Prop({default: false, required: false})
  plannerActive!: boolean;

  @Prop({default: null, required: false})
  roomName!: string;

  goalsList: Array<{ name: string, x: number, w: number, color: string, items: Array<any> }> = [
    {
      name: 'Launch Coursera',
      x: 0,
      w: 180,
      color: '#4a677d',
      items: []},
    {
      name: 'Partner workshops',
      x: 93,
      w: 186,
      color: '#4E6068',
      items: []},
    {
      name: 'Pembio x Wintour',
      x: 186,
      w: 220,
      color: '#773F75',
      items: [
        'Book the event venue',
        'Coordinate Entertainment',
        'Design and send invitations ',
        'Confirm with event vendors',
        'Contact Anna for catering ',
        'Follow-up with RSVP’s',
      ]},
  ];

  confettiOrigin() {
    const options = [
      { x: 0.8, y: 0.9 },
      { x: 0.9, y: 0.9 },
      { x: 0.9, y: 0.4 },
      { x: 0.9, y: 0.3 }
    ];

    // Generate a random index to select an option
    const randomIndex = Math.floor(Math.random() * options.length);

    // Return the randomly selected option
    return options[randomIndex];
  }

  colors: Array<string> = [
    '#7b7343',
    '#4a677d',
    '#773f75',
    '#bd5fb4',
    '#4a677d',
    '#374ea9',
    '#776015',
    '#2e825f',
    '#4e6068',
    '#66737a',
    '#717171',
    'No color'
  ];

  tasks: Array<{ text: string; completed: boolean }> = [
    { text: 'Book the event venue', completed: false },
    { text: 'Coordinate Entertainment', completed: false },
    { text: 'Design and send invitations', completed: false },
    { text: 'Confirm with event vendors', completed: false },
    { text: 'Contact Anna for catering', completed: false },
    { text: 'Follow-up with RSVP’s', completed: false },
  ];

  firstNames: Array<string> = [
    'Anna',
    'Uma',
    'Eric',
    'Adam',
  ];

  getRandMembers() {
    const randomIndex = Math.floor(Math.random() * this.firstNames.length);
    this.currentMember = this.firstNames[randomIndex];
  }

  // Create a method to update tasks with a delay
  async updateTasksWithDelay() {
    for (let i = 0; i < 2; i++) {
      await this.delay(1500); // Wait for 1.5 seconds
      await this.markAsCompleted(i);
    }
  }

  async markAsCompleted(index: any) {
    this.tasks[index].completed = true;
    this.latestCompletedMessage = this.tasks[index].text;
    this.getRandMembers();
    //this.playSound();
    this.completedCount++;
    this.triggerConfetti = true;
    await this.delay(1000); // Wait for 1 second
    this.tasks.splice(index, 1); // Remove the completed task from the list
    this.triggerConfetti = false
  }

  // Helper function to create a delay using Promises
  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  playSound() {
    if(this.$store.state.mute_notification_sound) {
      let audio = new Audio(require('@/assets/audio/initiative-check.mp3'));
      audio.volume = 0.1;
      audio.play();
    }
  }

  // Call the function when the component is mounted
  mounted() {

    if(this.runAnimation) {
      this.updateTasksWithDelay();
    }
    this.currentWeek = getWeek(new Date);
  }
}
